.mobile-header {
    width: calc(100vw - 94px);
    max-width: 80vw;
    height: 100vh;
    position: absolute !important;
    top: 60px;
    right: 0;
    top: 0;
    padding: 16px;
    background-color: #c73727;
    .menu-top-content {
        height: 52px;
        position: relative;
        .close-icon {
            position: absolute;
            right: 8px;
            top: 4px;
            color: #fff;
        }
    }
    .menu-content {
        p {
            color: #ffd4c7 !important;
            padding: 0 18px;
        }
    }
    .sub-menu {
        padding: 0 5px;
        p {
            color: #ffd4c7 !important;
        }
    }
}
