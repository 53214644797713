:export {
  paper: #ffffff;
  primaryLight: #eef2f6;
  primary200: #90caf9;
  primaryMain: #2196f3;
  primaryDark: #1e88e5;
  primary800: #1565c0;
  secondaryLight: #ede7f6;
  secondary200: #b39ddb;
  secondaryMain: #673ab7;
  secondaryDark: #FF6750;
  secondary800: #4527a0;
  successLight: #b9f6ca;
  success200: #69f0ae;
  successMain: #00e676;
  successDark: #00c853;
  errorLight: #ef9a9a;
  errorMain: #f44336;
  errorDark: #c62828;
  orangeLight: #fbe9e7;
  orangeMain: #ffab91;
  orangeDark: #d84315;
  warningLight: #fff8e1;
  warningMain: #ffe57f;
  warningDark: #ffc107;
  grey50: #F8FAFC;
  grey100: #EEF2F6;
  grey200: #E3E8EF;
  grey300: #CDD5DF;
  grey500: #697586;
  grey600: #4B5565;
  grey700: #364152;
  grey900: #121926;
  darkPaper: #111936;
  darkBackground: #1a223f;
  darkLevel1: #29314f;
  darkLevel2: #212946;
  darkTextTitle: #d7dcec;
  darkTextPrimary: #bdc8f0;
  darkTextSecondary: #8492c4;
  darkPrimaryLight: #eef2f6;
  darkPrimaryMain: #2196f3;
  darkPrimaryDark: #1e88e5;
  darkPrimary200: #90caf9;
  darkPrimary800: #1565c0;
  darkSecondaryLight: #d1c4e9;
  darkSecondaryMain: #7c4dff;
  darkSecondaryDark: #651fff;
  darkSecondary200: #b39ddb;
  darkSecondary800: #6200ea;
}

.fullscreen .react-images__blanket {
  z-index: 1200;
}

.apexcharts-legend-series .apexcharts-legend-marker {
  margin-right: 8px;
}

.scrollbar-container .ps__rail-y:hover > .ps__thumb-y, .scrollbar-container .ps__rail-y:focus > .ps__thumb-y, .scrollbar-container .ps__rail-y.ps--clicking .ps__thumb-y {
  background-color: #697586;
  width: 5px;
}
.scrollbar-container .ps__thumb-y {
  background-color: #697586;
  border-radius: 6px;
  width: 5px;
  right: 0;
}

.scrollbar-container.ps.ps--active-y > .ps__rail-y,
.scrollbar-container > .ps.ps--active-y > .ps__rail-y {
  width: 5px;
  background-color: transparent !important;
  z-index: 999;
}
.scrollbar-container.ps.ps--active-y > .ps__rail-y:hover, .scrollbar-container.ps.ps--active-y > .ps__rail-y.ps--clicking,
.scrollbar-container > .ps.ps--active-y > .ps__rail-y:hover,
.scrollbar-container > .ps.ps--active-y > .ps__rail-y.ps--clicking {
  width: 5px;
  background-color: transparent;
}
.scrollbar-container.ps.ps--scrolling-y > .ps__rail-y, .scrollbar-container.ps.ps--scrolling-x > .ps__rail-x,
.scrollbar-container > .ps.ps--scrolling-y > .ps__rail-y,
.scrollbar-container > .ps.ps--scrolling-x > .ps__rail-x {
  opacity: 0.4;
  background-color: transparent;
}

@keyframes wings {
  50% {
    transform: translateY(-40px);
  }
  100% {
    transform: translateY(0px);
  }
}
@keyframes blink {
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes bounce {
  0%, 20%, 53%, to {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }
  40%, 43% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -5px, 0);
  }
  70% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -7px, 0);
  }
  80% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translateZ(0);
  }
  90% {
    transform: translate3d(0, -2px, 0);
  }
}
@keyframes slideY {
  0%, 50%, 100% {
    transform: translateY(0px);
  }
  25% {
    transform: translateY(-10px);
  }
  75% {
    transform: translateY(10px);
  }
}
@keyframes slideX {
  0%, 50%, 100% {
    transform: translateX(0px);
  }
  25% {
    transform: translateX(-10px);
  }
  75% {
    transform: translateX(10px);
  }
}

